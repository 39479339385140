@import '@hh.ru/magritte-ui/breakpoints';

.controls-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .screen-gt-s({
        flex-direction: row;
    });
}

.controls-text {
    display: flex;
    margin: auto;
}
