@import '~bloko/common/styles/text-base';

.title {
    text-transform: lowercase;
}

.reason-text {
    .bloko-text-medium();

    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}
