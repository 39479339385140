@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/colors';

.preview-loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    margin: auto;
}

.preview-iframe {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: -15px;
    min-height: 166px;
}

.content-wrapper {
    position: relative;
}
