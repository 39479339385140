@import '@hh.ru/magritte-design-tokens/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.vacancy-advice-card {
    margin-bottom: @magritte-core-space-4-x;
}

.vacancy-advice-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vacancy-advice-icon-hide {
    opacity: 1;
    transition: opacity @magritte-semantic-animation-ease-in-out-100-duration
        @magritte-semantic-animation-ease-in-out-tiger-100-timing-function;

    @media @screen-magritte-gt-s {
        opacity: 0;
    }
}

.vacancy-advice-card:hover .vacancy-advice-icon-hide {
    opacity: 1;
}

.vacancy-advice-card-content {
    display: flex;
    flex-direction: column;
    padding: @magritte-core-space-3-x;
    padding-top: @magritte-core-space-2-x;
    padding-bottom: 0;
    margin-bottom: @magritte-core-space-4-x;
    gap: @magritte-core-space-4-x;
}
