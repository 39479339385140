@import '@hh.ru/magritte-ui/breakpoints';

.transfer-vacancies-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .screen-gt-s({
        height: 370px;
    });
}
