@import '~bloko/common/styles/media/_screen';

.wrapper {
    @media @screen-gt-xs {
        width: 600px;
    }
}

.footer-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;

    @media @screen-gt-xs {
        flex-direction: row;
        justify-content: space-between;
    }
}

// tempexp_30986_start
.button-wrapper-exp {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;

    @media @screen-gt-xs {
        display: block;
    }
}

// tempexp_30986_end
