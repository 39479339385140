@import '@hh.ru/magritte-design-tokens/base-variables';

.vacancy-advice-modal-title {
    display: flex;
    align-items: baseline;
    gap: @magritte-core-space-2-x;
}

.vacancy-advice-modal-bottom-sheet-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vacancy-advice-modal-bottom-sheet-footer-column {
    flex-direction: column;
    gap: @magritte-core-space-4-x;
}

.vacancy-advice-modal-bottom-sheet-title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
