@import '@hh.ru/magritte-design-tokens/base-variables';

.feedback-modal-negative-section {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-6-x;
}

.feedback-modal-content {
    .feedback-modal-negative-section();

    align-items: center;
}
