@import '../../../../../../bloko/common/styles/media/_screen';

.additional-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}

.additional-item {
    width: 100%;

    @media @screen-gt-xs {
        width: 50%;
    }
}
