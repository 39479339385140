@import '~bloko/common/styles/media/_screen';

.footer {
    display: flex;
    justify-content: space-between;
}
.right {
    display: flex;
    align-items: center;
    gap: 20px;
    @media @screen-lt-s {
        gap: 20px;
    }
}
