.buttons {
    display: flex;
    align-items: center;
    gap: 24px;
}

.buttons-mobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 16px 0;
}

.header-modal {
    padding: 0 16px 16px;
}
