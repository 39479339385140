.wrapper {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}
