.title {
    text-transform: lowercase;
}

.reason-text {
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }
}
