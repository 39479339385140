.form-container {
    height: 100%;
}

.form-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.footer {
    display: flex;
    gap: 5px;
    margin-top: 8px;
    justify-content: flex-end;
}
