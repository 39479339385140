@import '~bloko/common/styles/spacing-base';

.archive-modal-content {
    display: flex;
    flex-direction: column;
    gap: @spacing-base * 4;
}

.archive-modal-reasons {
    display: flex;
    flex-direction: column;
    gap: @spacing-base * 2;
}

.archive-modal-reason-wrapper {
    display: flex;
    align-items: center;
    gap: @spacing-base * 2;
}
