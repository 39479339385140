@import '~bloko/common/styles/media/_screen';

.content {
    @media @screen-gt-xs {
        width: 600px;
    }
}
.footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
    @media @screen-gt-xs {
        flex-direction: row;
        justify-content: space-between;
    }
}
